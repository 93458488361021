<!-- 购买门票 -->
<template>
  <div>
    <!--    门票购买-->
    <p style="text-align: left;font-size: 30px;margin-top: 0;margin-bottom: 10px;">
      {{ $t('Ticket.purchase') }}
    </p>
    <el-form label-position="left" label-width="auto">
      <!-- 第一步  选择-->
      <div v-if="NowStep === 1">
        <el-table
            v-loading="tableLoading"
            :data="NowStepOne"
            size="mini"
            border
            fit
            highlight-current-row
            style="width: 100%;"
            max-height="380"
        >
          <!--门票产品名称-->
          <el-table-column :label="$t('Ticket.product.name')" prop="name" align="center"/>
          <!--门票产品编码-->
          <el-table-column :label="$t('Ticket.product.code')" align="center" />
<!--            <template v-slot="{row}">-->
<!--              <span style="color: #0000FF; cursor: pointer; text-decoration:underline;"-->
<!--                    @click="gotoviewBtn(row)"> {{ row.goodscode }}</span>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <!--门票单价-->
          <el-table-column :label="$t('Ticket.Unit.Price')" prop="standardPrice" align="center"/>
          <!--购买数量-->
          <el-table-column :label="$t('Purchase.quantity')" width="120" prop="qty" align="center">
            <template slot-scope="{row}">
              <el-input-number
                  v-model="row.qty"
                  size="mini"
                  :min="0"
                  @change="qtyChange(row)"
                  style="width:100px;"
              />
            </template>
          </el-table-column>
        </el-table>
        <span style="float: right; margin-top: 10px">
        <!--下一步-->
        <el-button type="warning" plain @click="nextBtn">{{ $t('button.next') }}</el-button>
      </span>
      </div>
      <!-- 第二步  下单-->
      <div v-if="NowStep === 2">
        <el-table :data="NowStepTwo" size="mini" border fit highlight-current-row style="width: 100%;" max-height="380">
          <!--门票产品名称-->
          <el-table-column :label="$t('Ticket.product.name')" prop="name" align="center"/>
          <!--门票产品编码-->
          <el-table-column :label="$t('Ticket.product.code')" prop="goodscode" align="center"/>
          <!--门票单价-->
          <el-table-column :label="$t('Ticket.Unit.Price')" prop="standardPrice" align="center"/>
          <!--购买数量-->
          <el-table-column :label="$t('Purchase.quantity')" prop="qty" align="center"/>
        </el-table>
        <p style="text-align: left">{{ $t('Ticket.total.amount') + ':' }}<span>&nbsp;&nbsp;{{ this.sum }}</span></p>
        <!--     经销商编号 -->
        <el-form-item
            :label="$t('miMemberNetwork.memberNo')"
            :rules="{required: true,message: this.$t('po.setInfo'),trigger: ['blur', 'change']}"
            size="mini"
            style="width: 400px; margin-top: 10px">
          <el-input v-model="memberNo" :placeholder="$t('')" clearable/>
        </el-form-item>
        <!--         支付密码 -->
        <el-form-item
            :label="$t('please.input.your.pay.password')"
            :rules="{required: true,message: this.$t('po.setInfo'),trigger: ['blur', 'change']}"
            size="mini"
            style="width: 400px; margin-top: 10px"
        >
          <el-input v-model.trim="payPassword" :placeholder="$t('')" clearable show-password/>
        </el-form-item>
        <span style="float: right">
         <!--      上一步-->
        <el-button type="warning" :loading="SaveLoading" plain
                   @click="nextBtnONe">{{ $t('button.previous') }}</el-button>
          <!--      下单-->
        <el-button :loading="SaveLoading" type="warning" @click="SaveBtn" plain>{{ $t('place.order') }}</el-button>
      </span>
      </div>
      <!-- 第三步  完成-->
      <div v-if="NowStep === 3">
        <div class="success">
          <img :src="require('@/assets/images/success.png')">
          <!--    恭喜您, 门票购买成功.     -->
          <div class="success-txt">{{ $t('congratulations.success') }}</div>
          <div>
            <!--  查看订单  -->
            <el-button icon="el-icon-s-home" @click="toTicketHistory">{{ $t('paypal.viewOrder') }}</el-button>
            <!--  继续购买  -->
            <el-button icon="el-icon-refresh" @click="onContinue">{{ $t('btn.continue.buy') }}</el-button>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {companyTicketList, orderTicketAdd} from '@/api/orderTicket/orderticket'

export default {
  name: "PurchaseTicket",
  data() {
    return {
      tableLoading: false,
      sum: 0,
      id: 0,
      memberNo: '',
      payPassword: '',
      NowStep: 1,
      SaveLoading: false,
      NowStepOne: [],
      NowStepTwo: []
    }
  },
  created() {
    this.getTicket()
  },
  methods: {
    qtyChange(row) {
      if (isNaN(row.qty)) {
        row.qty = 0
      }
      if (row.qty <= 0) {
        row.qty = 0
      }
      if (row.qty % 1 !== 0) {
        row.qty = Math.floor(row.qty)
      }
    },
    gotoviewBtn(row) {
      this.$router.push({name: 'GoodsDetail', query: {...row, isDoing: 'false'}})
    },
    getTicket() {
      if (this.$route.query.id) {
        this.NowStepOne.push(this.$route.query)
      } else if (this.$route.query.fuwudian === '0') {
        let goodscode = this.$route.query.goodscode
        let num = this.$route.query.num
        this.tableLoading = true
        companyTicketList().then(res => {
          res.data.forEach(v => {
            v.qty = 0
          })
          this.NowStepOne = res.data
          if (this.NowStepOne && this.NowStepOne.length === 1) {
            this.NowStepOne[0].qty = goodscode === this.NowStepOne[0].goodscode ? num : 0
            this.nextBtn()
            this.tableLoading = false
          } else if (this.NowStepOne && this.NowStepOne.length > 0) {
            for (let a = 0; a < this.NowStepOne.length; a++) {
              if (goodscode === this.NowStepOne[a].goodscode)
                this.NowStepOne[a].qty = num
            }
            this.nextBtn()
            this.tableLoading = false
          }
        }).catch(() => {
          this.tableLoading = false
        })
      } else {
        this.tableLoading = true
        companyTicketList().then(res => {
          res.data.forEach(v => {
            v.qty = 0
          })
          this.NowStepOne = res.data
          this.tableLoading = false
        }).catch(() => {
          this.tableLoading = false
        })
      }
    },
    nextBtnONe() {
      this.NowStep = 1
      this.NowStepTwo = []
      this.sum = 0
    },
    nextBtn() {
      this.NowStepOne.forEach(v => {
        if (v.qty > 0) {
          v.sum = Number(v.standardPrice) * Number(v.qty)
          this.NowStepTwo.push(v)
        }
      })
      if (this.NowStepTwo && this.NowStepTwo.length > 0) {
        if (this.NowStepTwo.length === 1) {
          this.NowStep = 2
          this.NowStepTwo.forEach(v => {
            this.sum += v.sum
          })
        } else {
          this.NowStepTwo = []
          this.getTicket()
          return this.$message.info('只能购买单场次门票') // 只能购买单场次门票
        }
      } else {
        this.$message.error(this.$t('Please.purchase.quantity'))
        this.NowStep = 1
      }
    },
    SaveBtn() {
      const model = {
        'details': this.NowStepTwo,
        'payPassword': this.payPassword,
        'memberNo': this.memberNo
      }
      this.SaveLoading = true
      orderTicketAdd({...model}).then(() => {
        this.NowStep = 3
        this.SaveLoading = false
      }).catch(err => {
        this.SaveLoading = false
        console.log(err)
      })
    },
    toTicketHistory() {
      this.NowStep = 1
      this.NowStepTwo = []
      this.$router.push({name: 'TicketHistory'})
    },
    onContinue() {
      this.NowStep = 1
      this.getTicket()
      this.NowStepTwo = []
    }
  }
}
</script>

<style scoped lang="scss">
.success {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    max-height: 120px;
  }

  .success-txt {
    display: inline-block;
    padding: 10px 0;
    font-size: 14px;
    font-weight: bold;
    width: 420px;

    span {
      color: #FF0000;
      font-size: 16px;
    }
  }

  .el-button {
    margin-top: 20px;
  }
}
</style>
